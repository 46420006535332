import React from "react"
import SEO from "../components/seo"
import RegulationsPageContent from "../components/regulationsPageContent";



const PrivacyPage = () => (
  <main>
    <SEO
      title="Regolamenti"
      description="Qui trovi la privacy policy del sito ceres.com"
    />
    <RegulationsPageContent />
  </main>
);

export default PrivacyPage;
