import React from 'react';
import ServicePageContent from "../servicePageContent/servicePageContent";

import contents from "./regulationsPageContent.yml";

const RegulationsPageContent = () => {

  const {
    title,
    downloadList,
    body
  } = contents;

  return (
    <ServicePageContent
      title={title}
      downloadList={downloadList}
    >{body}</ServicePageContent>
  )
};

export default RegulationsPageContent;
