import React from 'react';

import "./servicePageContent.scss";
import ButtonLink from "../buttonLink";

const ServicePageContent = (props) => {

  const {
    title,
    downloadList,
    children
  } = props;

  return (
    <div className={`ServicePageContent`}>
      <div className="inner-content">
        <h1 dangerouslySetInnerHTML={{__html: title}}/>
        <div dangerouslySetInnerHTML={{__html: children}}/>

        {downloadList &&
          <div className="download-list__wrapper">
            {downloadList.map((download, i) => {
              return (
                <div
                  className="download-list"
                  key={`download-${i}`}
                >
                  <div className="download-list__title" dangerouslySetInnerHTML={{__html: download.title}} />
                  <div className="download-list__cta">
                    <ButtonLink
                      to={download.cta.href}
                      target={download.cta.target}
                      label={download.cta.label}
                      customClass={"button-link--not-full"}
                      trackingCategory={`Download ${download.cta.href}`}
                      trackingLabel={download.cta.label}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        }

      </div>
    </div>
  )
};

export default ServicePageContent;
